export const useTime = () => {
  const currentTime = ref('');
  const currentTimezoneTime = ref('');
  const currentUTCTime = ref('');
  const authStore = useAuthStore();
  // const timezone = ref(useAuthStore().timezone);

  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const getCurrentTimezoneTime = () => {
    const now = new Date();
    return formatUSDate(now, true, authStore.timezone);
  };

  const getCurrentUTCTime = () => {
    const now = new Date();
    return formatUSDate(now, true, 'Etc/UTC');
  };

  const updateTime = () => {
    currentTime.value = getCurrentTime();
  };

  const updateTimezoneTime = () => {
    currentTimezoneTime.value = getCurrentTimezoneTime();
  };

  const updateUTCTime = () => {
    currentUTCTime.value = getCurrentUTCTime();
  };

  let timezoneInterval: ReturnType<typeof setInterval> | null = null;
  let interval: ReturnType<typeof setInterval> | null = null;
  let utcInterval: ReturnType<typeof setInterval> | null = null;

  watch(
    () => authStore.timezone,
    (value) => {
      currentTime.value = '';
      currentTimezoneTime.value = '';
      currentUTCTime.value = '';

      updateTime();
      updateTimezoneTime();
      updateUTCTime();

      if (timezoneInterval !== null) clearInterval(timezoneInterval);
      if (interval !== null) clearInterval(interval);
      if (utcInterval !== null) clearInterval(utcInterval);

      timezoneInterval = setInterval(updateTimezoneTime, 1000);
      interval = setInterval(updateTime, 1000);
      utcInterval = setInterval(updateUTCTime, 1000);
    },
    { immediate: true }
  );

  const computedTime = computed(() => {
    return `Local: ${currentTime.value}`;
  });

  const computedTimezoneTime = computed(() => {
    return `${useAuthStore().timezone}: ${currentTimezoneTime.value}`;
  });

  const computedUTCTime = computed(() => {
    return `UTC: ${currentUTCTime.value}`;
  });

  return {
    computedTime,
    computedTimezoneTime,
    computedUTCTime,
  };
};
