export const useAnime = () => {
  const { $anime }: { $anime: any } = useNuxtApp();
  const appConfigStore = useAppConfigStore();

  const animateColorMode = async (colorMode: string) => {
    await window.scrollTo({ top: 0, behavior: 'instant' });

    const colorModeAnimationElement = document.querySelector(
      '#color-mode-animation-element'
    ) as HTMLElement | null;
    const colorModeAnimationWrapper = document.querySelector(
      'body'
    ) as HTMLElement | null;
    if (colorModeAnimationElement && colorModeAnimationWrapper) {
      colorModeAnimationElement.style.display = 'block';
      colorModeAnimationWrapper.style.overflow = 'hidden';
    }

    var tl = $anime.timeline({
      autoplay: false,
      easing: 'easeInOutQuad',
    });

    tl.add({
      targets: '#color-mode-animation-element',
      width: '200vw',
      height: '200vh',
      duration: 350,
    });
    tl.add({
      targets: '#color-mode-animation-element',
      duration: 200,
      changeBegin: function () {
        appConfigStore.colorMode =
          appConfigStore.colorMode === 'dim' ? 'light' : 'dim';
      },
    });
    tl.add({
      targets: '#color-mode-animation-element',
      backgroundColor: `${colorMode !== 'dim' ? '#010101' : '#ffffff'}`,
      duration: 400,
    });
    tl.add({
      targets: '#color-mode-animation-element',
      opacity: 0,
      duration: 500,
    });

    tl.finished.then(function () {
      if (colorModeAnimationElement && colorModeAnimationWrapper) {
        colorModeAnimationElement.style.opacity = '1';
        colorModeAnimationElement.style.width = '1vw';
        colorModeAnimationElement.style.height = '1vh';
        colorModeAnimationElement.style.display = 'none';
        colorModeAnimationWrapper.style.overflow = 'auto';
      }
    });
    tl.play();
  };

  return {
    animateColorMode,
  };
};
